import * as React from 'react';
import cn from 'classnames';
import css from './Heading.scss';

const H3 = ({ children, className, ...otherProps }: React.HTMLProps<HTMLHeadingElement>) => (
  <h3 className={cn(css.Heading, css.H3, className)} {...otherProps}>
    {children}
  </h3>
);

export default H3;
