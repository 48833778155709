import * as React from 'react';
import cn from 'classnames';
import css from './Heading.scss';

interface Props extends React.HTMLProps<HTMLHeadingElement> {
  light?: boolean;
}

const H5 = ({ children, light, className, ...otherProps }: Props) => (
  <h5 className={cn(css.Heading, css.H5, { [css[`H5--light`]]: !!light }, className)} {...otherProps}>
    {children}
  </h5>
);

export default H5;
