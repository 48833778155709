import * as React from 'react';
import cn from 'classnames';
import { H6 } from 'styleguide/components/Heading';
import HtmlContent from 'styleguide/components/HtmlContent/HtmlContent';
import Collapse from 'styleguide/components/Collapse/Collapse';
import { clickFAQ, clickEmailUs } from 'api/gtm';
import AppContext from 'contexts/AppContext/AppContext';
import { useLocation } from 'react-router-dom';
import { isProductPage } from 'libs/utils/pageUtils';

interface Props {
  questions: Array<{ question: string; answer: string }>;
  expanded?: boolean;
}

const FAQ = ({ questions, expanded }: Props) => {
  const appContext = React.useContext(AppContext);
  const location = useLocation();

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isProductPage(location.pathname, appContext.store.cmsPages)) {
      return;
    }
    const target = e.target as HTMLElement;
    if (
      (target.tagName === 'A' && target.textContent?.toLowerCase() === 'email us') ||
      target.textContent?.toLowerCase() === 'contact us'
    ) {
      clickEmailUs({ location: 'pdp_faq' });
    }
  };

  return (
    <div className="w-full">
      <Collapse>
        {questions.map((faq, index) => (
          <div className={cn('mb-10 last:mb-0')} key={index}>
            <Collapse.Item
              title={<H6 className="my-0">{faq.question}</H6>}
              onToggle={() => clickFAQ({ question: faq.question })}
              body={
                <HtmlContent
                  content={faq.answer}
                  className={cn('!prose-a:text-blue !max-w-none, !prose m-4 3xl:!max-w-4xl')}
                  onClick={handleClick}
                />
              }
              key={index}
              isWide
              withPadding
              classNameBody="overflow-visible"
              initiallyExpanded={expanded}
            />
          </div>
        ))}
      </Collapse>
    </div>
  );
};

export default FAQ;
